import React from 'react';
import Button from './Button';
import styles from './UntouchedRequestBlock.module.css';
import StepContentHeader from './StepContentHeader';
import {redirect, useLocation, useNavigate} from 'react-router-dom';
const UntouchedRequestBlock = ({requestId}) => {
    const navigate = useNavigate();
    const redirectToInterbankPage = () => {
        const interbankRedirectUri = process.env.REACT_APP_INTERBANK_REDIRECT_URI || 'https://accept.lls.by/oauth2/callback';
        const interbankPageUrl = process.env.REACT_APP_INTERBANK_PAGE_URL;
        const clientId = process.env.REACT_APP_INTERBANK_CLIENT_ID;
        const host = window.location.host;
        const scope = process.env.REACT_APP_INTERBANK_SCOPE || 'msi_subject msi_national_id_number msi_id_document msi_contact msi_natural_person_tax_info';
        const redirectUrl = `${interbankPageUrl}?client_id=${clientId}&state=${requestId}&response_type=code&authentication=online_otp&redirect_uri=${interbankRedirectUri}&scope=${scope}`;
        window.location.replace(redirectUrl);
        //navigate(`/oauth2/callback?code=123&state=${requestId}`);
    };
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <StepContentHeader>Уважаемый клиент!</StepContentHeader>
                <p className={styles.text}>
                    Вам необходимо пройти процедуру аутентификации в МСИ.
                </p>
                <p className={styles.text}>
                    После этого Вы будете перенаправлены обратно, на страницу Лайт Лизинг.
                </p>
                <div className={styles.buttonContainer}>
                    <Button filled={false} onClick={redirectToInterbankPage}>Перейти в МСИ</Button>
                </div>
            </div>
        </div>
    );
};

export default UntouchedRequestBlock;